import React from 'react';
import { Button, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';

import { Popup } from '../CommonComponents';
import toastMessage from '../../utils/toast';

import {
    // JobType,
    OfferType,
    // OrderType
} from '../../types';
import { formatNumber } from '../../utils/parsing';
import { acceptOffer } from '../../api/offers';
import { useDispatch } from '../../redux/hooks';
import { resetLastAllOpenJobsFetch } from '../../redux/actions/market';

type PropsType = {
    // order: OrderType | JobType;
    offer: OfferType;
    userIsOrderOwner?: boolean;
    refetch: () => void;
    offeringTenantName? : string;
};

export default function AcceptOffer({
    offer,
    userIsOrderOwner,
    refetch,
    offeringTenantName
}: PropsType) {
    const dispatch = useDispatch();
    const [popupIsOpen, setPopupIsOpen] = React.useState(false);

    const accept = React.useCallback(async () => {
        try {
            await acceptOffer({ id: offer.id, price: offer.price });
            dispatch(resetLastAllOpenJobsFetch()); // Refetch allJobs to get correct group/watchlist stats
            toastMessage('Uppdraget tilldelat!');
            setPopupIsOpen(false);
        } catch (e) {
            toastMessage(e, 'Kunde inte acceptera prisförslag, försök igen');
        } finally {
            refetch();
        }
    }, [dispatch, offer.id, offer.price, refetch]);

    return (
        <>
            <Popup
                open={popupIsOpen}
                title="Acceptera prisförslag"
                okLabel="Acceptera"
                handleOk={accept}
                handleClose={() => setPopupIsOpen(false)}
            >
                <Typography>
                    {`Vill du verkligen acceptera erbjudandet ${
                        formatNumber(offer.price, 'SEK')
                    }${offeringTenantName
                        ? ` från ${offeringTenantName}`
                        : ''
                    }?`}
                </Typography>

                {userIsOrderOwner ? (
                    <Typography>
                        Uppdraget kommer att tilldelas och övriga erbjudanden kommer att avböjas.
                    </Typography>
                ) : (
                    <Typography>
                        Genom att acceptera prisförslaget kommer du att bli tilldelad uppdraget.
                    </Typography>
                )}
            </Popup>

            <Button
                variant="contained"
                color="primary"
                startIcon={<Check />}
                onClick={() => setPopupIsOpen(true)}
                fullWidth
            // disabled={!order.isOpen}
            >
                Acceptera
            </Button>
        </>
    );
}
