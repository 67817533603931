import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { JobType } from '../../../types';

type PropsType = {
    entity: JobType
};

export default function DhlDetails({ entity }: PropsType) {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                    Intresseanmälan
                </Typography>
            </Grid>

            <Grid item xs={12}>
                Detta är ett uppdrag från DHL Overflow.
            </Grid>

            <Grid item xs={12}>
                Ring DHL Trafikcenter för mer info.
            </Grid>

            <Grid item xs={12}>
                {`Välj "avgående område", kontor ${entity.consignorOffice}.`}
            </Grid>

            <Grid item xs={12}>
                <Button
                    variant="contained"
                    startIcon={<Phone />}
                    onClick={() => window.open('tel:0771-183600')}
                    fullWidth
                >
                    0771 - 183600
                </Button>
            </Grid>
        </Grid>
    );
}
