import React from 'react';
import { Badge, Button } from '@mui/material';
import { Message } from '@mui/icons-material';

import {
    // JobType,
    OfferType,
    // OrderType
} from '../../types';
import OfferChatPopup from './OfferChatPopup';

type PropsType = {
    // order: OrderType | JobType;
    offer: OfferType | null;
    orderId: number | null;
    refetch: () => void;
    recipient: string;
    sx?: any;
};

export default function OfferChatButton({
    // order,
    offer,
    orderId,
    refetch,
    recipient,
    sx
}: PropsType) {
    const [popupIsOpen, setPopupIsOpen] = React.useState(false);

    const onClick = React.useCallback(async (e) => {
        e.stopPropagation();
        setPopupIsOpen(true);
    }, []);

    return (
        <>
            <OfferChatPopup
                open={popupIsOpen}
                id={offer?.id}
                orderId={orderId}
                recipient={recipient}
                canWrite
                // canWrite={offer?.canWrite}
                close={() => {
                    setPopupIsOpen(false);
                    refetch();
                }}
            />

            <Badge color="error" badgeContent={offer?.unreadMessageCount ?? 0} sx={sx}>
                <Button
                    variant="contained"
                    color="info"
                    startIcon={<Message />}
                    onClick={onClick}
                    fullWidth
                >
                    {
                        offer?.messageCount && offer?.messageCount > 0
                            ? `${offer?.messageCount} Meddelande${offer?.messageCount !== 1 ? 'n' : ''}`
                            : 'Starta chatt'
                    }
                </Button>
            </Badge>
        </>
    );
}
