import React from 'react';
import {
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import {
    Add,
    CheckBox,
    CheckBoxOutlineBlank,
    Edit,
    IndeterminateCheckBox,
    Info
} from '@mui/icons-material';

import { OpenCapacityType, OrderForCapacitySuggestionType } from '../../../types';
import { Popup, Table } from '../../CommonComponents';
import { getFillFactorPercentage, getOrderDate, getOrderExpiryDate } from '../../../utils/order';
import { getOrdersForCapacitySuggestions } from '../../../api/orders';
import { suggestCapacityOrders } from '../../../api/capacities';
import toast from '../../../utils/toast';
import OrderFormPopup from './OrderFormPopup';
import { formatNumber } from '../../../utils/parsing';

type PropsType = {
    capacity: OpenCapacityType,
    refetch: () => void
};

function getDisallowedSuggestionText(order: OrderForCapacitySuggestionType, capacity: OpenCapacityType) {
    if (order.status !== 'PUBLISHED') {
        return 'Uppdraget är inte publicerat. Du behöver redigera innan du kan föreslå det.';
    }
    if (capacity.isPublic) {
        if (!order.isPublic) {
            return 'Uppdraget måste vara publicerat publikt. Du behöver redigera uppdraget innan du kan föreslå det.';
        }
    } else if (!order.isPublic && !capacity.groups.some((cg) => order.groupIds.includes(cg.id))) {
        return `Uppdraget måste vara publicerat publikt eller i ${capacity.groups.length === 1
            ? 'gruppen'
            : 'en av grupperna'
            } ${capacity.groups.map((g) => g.name).join(', ')}. Du behöver redigera innan du kan föreslå det.`;
    }
    if (getOrderExpiryDate(order) < new Date()) {
        return 'Datumet för senaste intresseanmälan är passerat. Du behöver redigera innan du kan föreslå uppdraget.';
    }

    if (getFillFactorPercentage({ capacity, order }) > 100) {
        return 'Godset får inte plats på den lediga kapaciteten. Du behöver redigera innan du kan föreslå uppdraget.';
    }

    return null;
}

export default function CapacitySuggestion({ capacity, refetch }: PropsType) {
    const [showPopup, setShowPopup] = React.useState(false);
    const [isPending, setIsPending] = React.useState(false);
    const [confirmEditOrderPopupOpen, setConfirmEditOrderPopupOpen] = React.useState(false);
    const [suggestedOrderIds, setSuggestedOrderIds] = React.useState(capacity.suggestedOrderIds);
    React.useEffect(() => (
        setSuggestedOrderIds(capacity.suggestedOrderIds)
    ), [capacity.suggestedOrderIds]);

    const toggleSuggestedOrder = React.useCallback((orderId: number) => {
        setSuggestedOrderIds((soids) => {
            if (soids.some((soid) => soid === orderId)) {
                return soids.filter((soid) => soid !== orderId);
            }
            return [...soids, orderId];
        });
    }, []);

    const SuggestOrderButton = React.useCallback((order: OrderForCapacitySuggestionType) => {
        const disallowedSuggestionText = getDisallowedSuggestionText(order, capacity);
        if (disallowedSuggestionText) {
            return (
                <Tooltip
                    title={<Typography variant="body2">{disallowedSuggestionText}</Typography>}
                    placement="left-start"
                >
                    <div>
                        <IconButton disabled>
                            <IndeterminateCheckBox />
                        </IconButton>
                    </div>
                </Tooltip>
            );
        }

        return (
            <IconButton
                aria-label="Föreslå uppdrag"
                onClick={() => toggleSuggestedOrder(order.id)}
                disabled={isPending}
            >
                {
                    suggestedOrderIds.some((soid) => soid === order.id)
                        ? <CheckBox color="primary" />
                        : <CheckBoxOutlineBlank />
                }
            </IconButton>
        );
    }, [capacity, isPending, suggestedOrderIds, toggleSuggestedOrder]);

    const [orders, setOrders] = React.useState<OrderForCapacitySuggestionType[]>([]);

    const fetchOrders = React.useCallback(async () => {
        setOrders(
            (await getOrdersForCapacitySuggestions())
                .slice().sort((a: OrderForCapacitySuggestionType, b: OrderForCapacitySuggestionType) => (
                    getDisallowedSuggestionText(a, capacity) === null ? -1 : 1
                ))
        );
    }, [capacity]);

    const submitSuggestedOrders = React.useCallback(async () => {
        try {
            setIsPending(true);
            await suggestCapacityOrders({ capacityId: capacity.id, orderIds: suggestedOrderIds });
            refetch();
            setIsPending(false);
            setShowPopup(false);
        } catch (e) {
            toast(e, 'Kunde inte föreslå uppdrag, försök igen');
            refetch();
            fetchOrders();
            setIsPending(false);
        }
    }, [capacity.id, fetchOrders, refetch, suggestedOrderIds]);

    const openPopup = React.useCallback(() => {
        fetchOrders();
        setShowPopup(true);
    }, [fetchOrders]);

    const [orderFormPopupOpen, setOrderFormPopupOpen] = React.useState(false);
    const [orderIdToEdit, setOrderIdToEdit] = React.useState<number | null>(null);

    const closeOrderFormPopup = React.useCallback(({ orderIdToSuggest }: { orderIdToSuggest?: number }) => {
        if (orderIdToSuggest) {
            setSuggestedOrderIds((soids) => [...soids, orderIdToSuggest]);
        }
        setOrderFormPopupOpen(false);
    }, []);

    const createOrder = React.useCallback(() => {
        setOrderIdToEdit(null);
        setOrderFormPopupOpen(true);
    }, []);

    const editOrder = React.useCallback(() => {
        setConfirmEditOrderPopupOpen(false);
        setOrderFormPopupOpen(true);
    }, []);

    const confirmEditOrder = React.useCallback((order) => {
        setOrderIdToEdit(order.id);
        if (order.status === 'PUBLISHED') {
            setConfirmEditOrderPopupOpen(true);
        } else {
            editOrder();
        }
    }, [editOrder]);

    const closeConfirmEditOrderPopup = React.useCallback(() => {
        setConfirmEditOrderPopupOpen(false);
    }, []);

    const [moreInfoOpen, setMoreInfoOpen] = React.useState(false);
    const toggleMoreInfoOpen = React.useCallback(() => {
        setMoreInfoOpen((open) => !open);
    }, []);

    const EditOrderButton = React.useCallback((order) => (
        <IconButton
            aria-label="Redigera"
            onClick={() => confirmEditOrder(order)}
            disabled={isPending}
        >
            <Edit />
        </IconButton>
    ), [confirmEditOrder, isPending]);

    const columns = React.useMemo(() => ([{
        name: 'Transportdatum',
        key: 'orderDate',
        getValue: getOrderDate
    }, {
        name: 'Upphämtningsort',
        key: 'pickupCity'
    }, {
        name: 'Leveransort',
        key: 'deliveryCity'
    }, {
        name: 'Totalvikt',
        key: 'grossWeight',
        type: 'kg' as 'kg'
    }, {
        name: 'Flakmeter',
        key: 'loadingMeters',
        type: 'number' as 'number'
    }, {
        name: 'Listpris',
        key: 'listPrice',
        type: 'currency' as 'currency'
        // }, {
        //     name: 'Extrasträcka',
        //     key: 'extraDistance',
        //     type: 'number' as 'number',
        //     getValue: (r: OrderForCapacitySuggestionType) => formatNumber(Math.round(r.id), 'km')
    }, {
        name: 'Fyllnadsgrad',
        key: 'fillFactor',
        type: 'number' as 'number',
        getValue: (r: OrderForCapacitySuggestionType) => formatNumber(Math.round(getFillFactorPercentage({ capacity, order: r })), '%')
    }, {
        name: 'Föreslå',
        key: 'customSuggest',
        padding: 'checkbox' as 'checkbox',
        align: 'right',
        getValue: SuggestOrderButton
    }, {
        name: '',
        key: 'customEdit',
        padding: 'checkbox' as 'checkbox',
        align: 'right',
        getValue: EditOrderButton
    }]), [EditOrderButton, SuggestOrderButton, capacity]);

    return !capacity ? null : (
        <>
            <OrderFormPopup
                isOpen={orderFormPopupOpen}
                orderId={orderIdToEdit}
                refetch={fetchOrders}
                close={closeOrderFormPopup}
            />
            <Popup
                open={confirmEditOrderPopupOpen}
                title="Vill du verkligen ändra ditt publicerade uppdrag?"
                body="Alla som anmält intresse kommer att meddelas att uppdraget har ändrats."
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={editOrder}
                handleClose={closeConfirmEditOrderPopup}
            />
            <Popup
                open={moreInfoOpen}
                title="Föreslå uppdrag - så funkar det"
                cancelLabel="Stäng"
                handleClose={toggleMoreInfoOpen}
            >
                <Typography sx={{ mb: 2 }}>
                    Här kan du föreslå ett eller flera av era publicerade uppdrag.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    Har ni inga publicerade uppdrag kan du snabbt lägga till ett med knappen &quot;Publicera nytt uppdrag&quot; längst.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    Uppdragen som föreslås måste vara publicerade publikt eller i någon av de grupper som den lediga kapaciteten är publicerad i.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    Om uppdraget ej går att föreslå så visas det med ett sträck i föreslå-knappen. Du kan då redigera uppdraget direkt med pennan-knappen längst till höger.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    Företaget kommer att få en notis om de uppdrag du föreslagit,
                    sen tar de ställning till om de vill lämna en intresseanmälan eller ej.
                </Typography>
            </Popup>

            <Popup
                open={showPopup && !orderFormPopupOpen}
                title="Föreslå uppdrag"
                okLabel="Skicka förslag"
                handleOk={submitSuggestedOrders}
                handleClose={() => setShowPopup(false)}
                okDisabled={isPending || !orders?.length}
                maxWidth="xl"
                CustomTitleContent={(
                    <Button
                        color="primary"
                        aria-label="info"
                        variant="outlined"
                        startIcon={<Info />}
                        onClick={toggleMoreInfoOpen}
                    >
                        Hur funkar det?
                    </Button>
                )}
                CustomButton={(
                    <Button
                        color="primary"
                        variant="outlined"
                        aria-label="Lägg till uppdrag"
                        startIcon={<Add />}
                        onClick={createOrder}
                    >
                        Publicera nytt uppdrag
                    </Button>
                )}
            >
                <Table
                    name="capacitySuggestions"
                    defaultOrderBy="nothing"
                    columns={columns}
                    data={orders}
                    emptyText="Du har inga publicerade uppdrag att föreslå."
                />
            </Popup>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        Föreslå uppdrag
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    {
                        capacity.suggestedOrderIds.length < 1
                            ? 'Ni har inte föreslagit några uppdrag.'
                            : `Ni har föreslagit ${capacity.suggestedOrderIds.length} uppdrag.`
                    }
                </Grid>

                <Grid item xs={12}>
                    {capacity.suggestedOrderIds.length ? (
                        <Button
                            color="primary"
                            variant="outlined"
                            aria-label="Ändra uppdragsförslag"
                            startIcon={<Edit />}
                            onClick={openPopup}
                            fullWidth
                        >
                            Ändra uppdragsförslag
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            variant="contained"
                            aria-label="Föreslå uppdrag"
                            onClick={openPopup}
                            fullWidth
                        >
                            Välj uppdrag att föreslå
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
