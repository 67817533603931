import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Badge,
    // Button,
    // Chip
} from '@mui/material';
import {
    AssignmentTurnedIn,
    LocalShipping,
    // Message
} from '@mui/icons-material';

import { Block, Table } from '../CommonComponents';
import { getOrderDate } from '../../utils/order';
import { formatCities, formatLoad } from '../../utils/parsing';
// import OfferChatPopup from './OfferChatPopup';
import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchActiveOffers, selectActiveOffers } from '../../redux/actions/offers';
import { ActiveOfferType } from '../../types';
import { OfferStatusChip, OfferPriceChip } from './OfferChips';

const FETCH_INTERVAL = 60 * 1000; // 60 seconds

function getTypeTag(o: ActiveOfferType) {
    return o.type === 'ORDER'
        ? <AssignmentTurnedIn color="action" />
        : <LocalShipping color="action" />;
}

function getMsgInfo(offer: ActiveOfferType) {
    return (
        <span style={{ paddingRight: 1 }}>
            <Badge color="error" badgeContent={offer.unreadMessageCount}>
                <span style={{ paddingRight: 15 }}>
                    {offer.messageCount}
                </span>
            </Badge>
        </span>
    );
}

export default function ActiveOffers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const activeOffers = useSelector(selectActiveOffers());

    const refetchActiveOffers = React.useCallback(() => {
        dispatch(fetchActiveOffers());
    }, [dispatch]);

    React.useEffect(() => {
        refetchActiveOffers();
        const intervalId = window.setInterval(refetchActiveOffers, FETCH_INTERVAL);

        return () => window.clearInterval(intervalId); // Runs before every effect-run, and on unmount
    }, [dispatch, refetchActiveOffers]);

    // const [offerForMessages, setOfferForMessages] = React.useState<ActiveOfferType | null>(null);
    // const closeMessagePopup = React.useCallback(() => {
    //     refetchActiveOffers();
    //     setOfferForMessages(null);
    // }, [refetchActiveOffers]);

    const onRowClick = React.useCallback(({ type, orderId }: any) => {
        if (type === 'ORDER') {
            navigate(`/orders/${orderId}`);
        } else {
            navigate(`/jobs/LL/${orderId}`);
        }
    }, [navigate]);

    // const getMsgBtn = React.useCallback((offer) => (
    //     <Badge color="error" badgeContent={offer.unreadMessageCount}>
    //         <Button
    //             variant="contained"
    //             color="info"
    //             startIcon={<Message />}
    //             onClick={(e) => {
    //                 e.stopPropagation();
    //                 setOfferForMessages(offer);
    //             }}
    //         >
    //             {`${offer.messageCount} Meddelande${offer.messageCount !== 1 ? 'n' : ''}`}
    //         </Button>
    //     </Badge>
    // ), []);

    return (
        <Block
            title="Aktiva intresseanmälningar"
        >
            {/* <OfferChatPopup
                open={!!offerForMessages?.id}
                id={offerForMessages?.id || null}
                orderId={offerForMessages?.orderId || null}
                recipient={offerForMessages?.tenantName || 'Meddelanden'}
                canWrite // Can safely assume true since backend only passes active
                close={closeMessagePopup}
            /> */}
            <Table
                name="dashboardActiveOffers"
                columns={[{
                    name: 'Typ',
                    key: 'type',
                    getValue: getTypeTag,
                }, {
                    name: 'Datum',
                    key: 'date',
                    getValue: getOrderDate
                }, {
                    name: 'Sträcka',
                    key: 'cities',
                    getValue: formatCities
                }, {
                    name: 'Gods',
                    key: 'load',
                    getValue: formatLoad
                }, {
                    name: 'Företag',
                    key: 'tenantName'
                }, {
                    name: 'Pris',
                    key: 'customPrice',
                    getValue: OfferPriceChip
                }, {
                    name: 'Status',
                    key: 'customStatus',
                    getValue: OfferStatusChip
                }, {
                    name: 'Meddelanden',
                    key: 'customMessages',
                    getValue: getMsgInfo,
                    align: 'right'
                }]}
                data={activeOffers}
                onRowClick={onRowClick}
                defaultOrderBy="date"
                emptyText="Ni har inga aktiva intresseanmälningar"
            />
        </Block>
    );
}
