import React from 'react';
import { Button, Typography } from '@mui/material';
import { NotInterested } from '@mui/icons-material';

import { Popup } from '../CommonComponents';
import toastMessage from '../../utils/toast';

import {
    // JobType,
    OfferType,
    // OrderType
} from '../../types';
import { formatNumber } from '../../utils/parsing';
import { rejectOffer } from '../../api/offers';

type PropsType = {
    // order: OrderType | JobType;
    offer: OfferType;
    refetch: () => void;
    offeringTenantName?: string;
};

export default function RejectOffer({
    offer,
    refetch,
    offeringTenantName
}: PropsType) {
    const [popupIsOpen, setPopupIsOpen] = React.useState(false);

    const reject = React.useCallback(async () => {
        try {
            await rejectOffer({ id: offer.id, price: offer.price });
            setPopupIsOpen(false);
            toastMessage('Prisförslag avböjt.');
        } catch (e) {
            toastMessage(e, 'Kunde inte avböja prisförslag, försök igen');
        } finally {
            refetch();
        }
    }, [offer.id, refetch]);

    return (
        <>
            <Popup
                open={popupIsOpen}
                title="Avböj prisförslag"
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={reject}
                handleClose={() => setPopupIsOpen(false)}
            >
                <Typography>
                    {`Vill du verkligen avböja erbjudandet ${
                        formatNumber(offer.price, 'SEK')
                    }${offeringTenantName
                        ? ` från ${offeringTenantName}`
                        : ''
                    }?`}
                </Typography>
                <Typography>
                    Du kan alltid skicka ett eget motbud om du vill.
                </Typography>
            </Popup>

            <Button
                variant="contained"
                color="error"
                startIcon={<NotInterested />}
                onClick={() => setPopupIsOpen(true)}
                fullWidth
            // disabled={!order.isOpen}
            >
                Avböj
            </Button>
        </>
    );
}
