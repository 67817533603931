import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Grid } from '@mui/material';

import FormContext, { FormContextValueType } from './FormContext';
import Validation from './validations/ValidationContext';

type PropsType = {
    children: React.ReactNode;
    onSubmit: () => Promise<void> | void;
    submitText?: string;
    submitDisabled?: boolean;
    useGrid?: boolean;
};

export default function SimpleForm(props: PropsType) {
    const {
        children,
        onSubmit,
        submitText = 'Skicka',
        submitDisabled = false,
        useGrid = false
    } = props;

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const submit = React.useCallback(async () => {
        try {
            setIsSubmitting(true);
            await onSubmit();
        } catch {
            // Error handling should be done in onSubmit
            console.log('Failed to submit form');
        } finally {
            setIsSubmitting(false);
        }
    }, [onSubmit]);

    const formContextValue = React.useMemo<FormContextValueType>(() => ({
        isSubmitting,
        submit
    }), [isSubmitting, submit]);

    return (
        <Validation>
            {(hasError: boolean) => (
                useGrid ? (
                    <Grid container spacing={2}>
                        <FormContext.Provider value={formContextValue}>
                            {children}
                        </FormContext.Provider>

                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                size="large"
                                loadingPosition="center"
                                loading={isSubmitting}
                                disabled={submitDisabled || isSubmitting || hasError}
                                fullWidth
                                onClick={submit}
                            >
                                {submitText}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <FormContext.Provider value={formContextValue}>
                            {children}
                        </FormContext.Provider>

                        <Box sx={{ mt: 4, mb: 2 }}>
                            <LoadingButton
                                variant="contained"
                                size="large"
                                loadingPosition="center"
                                loading={isSubmitting}
                                disabled={submitDisabled || isSubmitting || hasError}
                                fullWidth
                                onClick={submit}
                            >
                                {submitText}
                            </LoadingButton>
                        </Box>
                    </>
                ))}
        </Validation>
    );
}
