import React from 'react';

import { Popup } from '../CommonComponents';
import { formatDateTime } from '../../utils/parsing';

type PropsType = {
    open: boolean;
    handleClose: () => void;
    expiresAt?: string;
    price: number;
};

export default function NotifySentOfferPopup({
    open,
    handleClose,
    expiresAt,
    price
}: PropsType) {
    return (
        <Popup
            open={open}
            title="Prisförslag skickat"
            handleClose={handleClose}
            cancelLabel="Stäng"
        >
            {`Ditt prisförslag på ${price} kr är nu skickat, giltigt ${expiresAt
                ? `till ${formatDateTime(expiresAt)}.`
                : 'tillsvidare.'
            }`}
            <p>
                Motparten har nu möjlighet att acceptera, avböja eller skicka ett motförslag.
            </p>
        </Popup>
    );
}
