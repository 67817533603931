import React from 'react';
import { Grid } from '@mui/material';

import { Block, Table } from '../CommonComponents';
import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllOffersByOrderId, selectAllOffersByOrderId } from '../../redux/actions/offers';
import { OrderType } from '../../types';
import { offerStatus, orderStatus } from '../../utils/status';
import { OfferPriceChip, OfferStatusChip } from '../Offers/OfferChips';
import EditOffer from '../Offers/EditOffer';
import RejectOffer from '../Offers/RejectOffer';
import AcceptOffer from '../Offers/AcceptOffer';
import OfferChatButton from '../Offers/OfferChatButton';

type PropsType = {
    order: OrderType;
    refetch: () => void;
}

const FETCH_INTERVAL = 60 * 1000; // 60 seconds

export default function OrderOffers({ order, refetch }: PropsType) {
    const dispatch = useDispatch();
    const offers = useSelector(selectAllOffersByOrderId(order.id));

    const fetchOffers = React.useCallback(() => {
        if (order.id) {
            dispatch(fetchAllOffersByOrderId(order.id));
        }
    }, [dispatch, order.id]);

    const completeRefetch = React.useCallback(() => {
        fetchOffers();
        refetch();
    }, [fetchOffers, refetch]);

    React.useEffect(() => {
        fetchOffers();
        const intervalId = window.setInterval(fetchOffers, FETCH_INTERVAL);

        return () => window.clearInterval(intervalId); // Runs before every effect-run, and on unmount
    }, [fetchOffers]);

    const getActionButtons = React.useCallback((offer) => (
        <Grid container spacing={1} justifyContent="flex-end">
            {
                !offer.isOwnOffer
                && offer.status === offerStatus.PENDING
                && order.status === orderStatus.PUBLISHED
                && !order.isArchived
                && offer.price
                && (
                    <>
                        <Grid item>
                            <AcceptOffer
                                offer={offer}
                                refetch={completeRefetch}
                                userIsOrderOwner
                                offeringTenantName={offer.tenantName}
                            />
                        </Grid>
                        <Grid item>
                            <RejectOffer
                                offer={offer}
                                refetch={completeRefetch}
                                offeringTenantName={offer.tenantName}
                            />
                        </Grid>
                    </>
                )
            }
            {order.status === orderStatus.PUBLISHED && !order.isArchived && (
                <Grid item>
                    <EditOffer order={order} offer={offer} refetch={fetchOffers} />
                </Grid>
            )}
            <Grid item>
                <OfferChatButton
                    offer={offer}
                    orderId={order.id}
                    recipient={offer.tenantName}
                    refetch={fetchOffers}
                />
            </Grid>
        </Grid>
    ), [completeRefetch, fetchOffers, order]);

    return (
        <Block
            title="Intresseanmälan"
        // ActionComponent={(
        //     <div>
        //         Historik
        //     </div>
        // )}
        >
            <Table
                name="dashboardActiveOffers"
                columns={[{
                    name: 'Skickat',
                    key: 'priceAt',
                    type: 'datetime'
                }, {
                    name: 'Företag',
                    key: 'tenantName'
                }, {
                    name: 'Prisförslag',
                    key: 'price',
                    getValue: OfferPriceChip
                }, {
                    name: 'Status',
                    key: 'customStatus',
                    getValue: OfferStatusChip
                }, {
                    name: '',
                    key: 'customActions',
                    align: 'right',
                    getValue: getActionButtons
                }]}
                data={offers}
                emptyText="Det finns inga intresseanmälningar."
            />
        </Block>
    );
}
