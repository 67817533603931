import { endOfDay } from 'date-fns';
import {
    CapacityType,
    JobType,
    OpenCapacityType,
    OrderForCapacitySuggestionType
} from '../types';
import { formatDate, formatDateTime, getTimeUntil } from './parsing';

type OrderForDateType = {
    firstPickupDate: string,
    lastPickupDate: string | null,
    firstDeliveryDate: string,
    lastDeliveryDate: string | null,
    firstPickupTime: string | null,
    lastPickupTime: string | null,
    firstDeliveryTime: string | null,
    lastDeliveryTime: string | null,
    expiresAt: string | null
};

export function getOrderDate(order: OrderForDateType) {
    const earliestDate = order.firstPickupDate;
    const latestDate = order.lastDeliveryDate || order.firstDeliveryDate;

    return earliestDate.slice(0, 10) === latestDate.slice(0, 10)
        ? formatDate(earliestDate)
        : `${formatDate(earliestDate)} - ${formatDate(latestDate)}`;
}

export type OrderTimeLeftType = {
    firstPickupDate: string,
    lastPickupDate: string | null,
    expiresAt?: string | null
}

export function getOrderExpiryDate({ firstPickupDate, lastPickupDate, expiresAt }: OrderTimeLeftType) {
    return expiresAt ? new Date(expiresAt) : endOfDay(new Date(lastPickupDate || firstPickupDate));
}

export function getFormattedOrderExpiryDate(order: OrderTimeLeftType) {
    return formatDateTime(getOrderExpiryDate(order));
}

export function getOrderTimeLeft(order: OrderTimeLeftType) {
    return getTimeUntil(getOrderExpiryDate(order));
}

export function getJobPublishedMetaText(job: JobType) {
    if (job.type === 'LL') {
        return `Publicerat ${formatDateTime(job.publishedAt)} av ${job.tenantName || '[dold uppdragsgivare]'} för ${job.isPublic ? 'Alla' : job.groups.map((g) => g.name).join(', ')}.`;
    }
    if (job.type === 'DHL') {
        return `Hämtat ${formatDateTime(job.publishedAt)} från DHL Overflow.`;
    }
    return '';
}

type GetFillFactorPropsType = {
    capacity: OpenCapacityType | CapacityType,
    order: OrderForCapacitySuggestionType
};

export function getFillFactorPercentage({ capacity, order }: GetFillFactorPropsType) {
    const attributes = [
        'grossWeight' as 'grossWeight',
        'loadingMeters' as 'loadingMeters',
        'volume' as 'volume',
        'palletPlaces' as 'palletPlaces',
        'pallets' as 'pallets'
    ];

    return attributes.reduce((acc, curr) => (
        // @ts-ignore
        order[curr] && capacity[curr] && (order[curr] / capacity[curr] > acc)
            // @ts-ignore
            ? (order[curr] / capacity[curr])
            : acc
    ), 0) * 100;
}
