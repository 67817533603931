import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Avatar,
    Badge,
    Box,
    Button,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import {
    Add,
    AssignmentTurnedIn,
    LocalShipping,
    Notifications,
    Search
} from '@mui/icons-material';

import { useDispatch, useSelector } from '../../redux/hooks';

import {
    Block,
    Header,
    Table
} from '../CommonComponents';
import { DoughnutChart } from '../CommonComponents/Charts';
import {
    Container,
} from '../StyledComponents';
import ActiveOffers from '../Offers/ActiveOffers';

import { selectUserProfile } from '../../redux/actions/users';
import {
    selectOrderStatusCount,
    fetchOrderStatusCount,
    setActiveOrderTab
} from '../../redux/actions/orders';
import {
    selectNotificationsCount
} from '../../redux/actions/notifications';

import {
    fetchJobStatusCount,
    selectJobStatusCount
} from '../../redux/actions/jobs';
import {
    fetchAllOpenCapacities,
    fetchAllOpenJobs,
    resetMarketFilters
} from '../../redux/actions/market';
import { fetchAllWatchlists, selectAllWatchlists } from '../../redux/actions/watchlists';
import { fetchAllGroups, selectMyGroups } from '../../redux/actions/groups';
import { setActiveTab } from '../../redux/actions/tabs';

export default function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userProfile = useSelector(selectUserProfile());
    const watchlists = useSelector(selectAllWatchlists());
    const myGroups = useSelector(selectMyGroups());

    const orderStatusCount = useSelector(selectOrderStatusCount());
    const jobStatusCount = useSelector(selectJobStatusCount());

    const notificationsCount = useSelector(selectNotificationsCount());

    React.useEffect(() => {
        // true = Only fetch if not recently fetched
        // Done here to get the correct values for published orders/jobs
        dispatch(fetchAllOpenJobs(true));
        dispatch(fetchAllOpenCapacities(true));

        dispatch(fetchOrderStatusCount());
        dispatch(fetchJobStatusCount());
        dispatch(fetchAllGroups());
        dispatch(fetchAllWatchlists());
    }, [dispatch]);

    const orderData = React.useMemo(() => ([
        {
            label: 'Opublicerade',
            value: orderStatusCount.DRAFTED ?? 0,
            onClick: () => {
                dispatch(setActiveOrderTab('DRAFTED'));
                navigate('/orders');
            }
        },
        {
            label: 'Publicerade',
            value: orderStatusCount.PUBLISHED ?? 0,
            onClick: () => {
                dispatch(setActiveOrderTab('PUBLISHED'));
                navigate('/orders');
            }
        },
        {
            label: 'Tilldelade',
            value: orderStatusCount.ACCEPTED ?? 0,
            onClick: () => {
                dispatch(setActiveOrderTab('ACCEPTED'));
                navigate('/orders');
            }
        },
        {
            label: 'Bekräftade',
            value: orderStatusCount.STARTED ?? 0,
            onClick: () => {
                dispatch(setActiveOrderTab('STARTED'));
                navigate('/orders');
            }
        }
    ]), [orderStatusCount, navigate, dispatch]);

    const jobData = React.useMemo(() => ([
        {
            label: 'Bokmärkta',
            value: jobStatusCount.BOOKMARKED ?? 0,
            onClick: () => {
                dispatch(setActiveTab({ tabsKey: 'jobs', activeTabKey: 'bookmarked' }));
                navigate('/jobs');
            }
        },
        {
            label: 'Intresseanmälda',
            value: jobStatusCount.OFFERED ?? 0,
            onClick: () => {
                dispatch(setActiveTab({ tabsKey: 'jobs', activeTabKey: 'offered' }));
                navigate('/jobs');
            }
        },
        {
            label: 'Tilldelade',
            value: jobStatusCount.ACCEPTED ?? 0,
            onClick: () => {
                dispatch(setActiveTab({ tabsKey: 'jobs', activeTabKey: 'accepted' }));
                navigate('/jobs');
            }
        },
        {
            label: 'Bekräftade',
            value: jobStatusCount.STARTED ?? 0,
            onClick: () => {
                dispatch(setActiveTab({ tabsKey: 'jobs', activeTabKey: 'started' }));
                navigate('/jobs');
            }
        },
        {
            label: 'Ledig kapacitet',
            value: jobStatusCount.CAPACITIES ?? 0,
            onClick: () => {
                dispatch(setActiveTab({ tabsKey: 'jobs', activeTabKey: 'capacities' }));
                navigate('/jobs');
            }
        }
    ]), [jobStatusCount, navigate, dispatch]);

    const activeOrdersCount = React.useMemo(() => {
        return (orderStatusCount.DRAFTED ?? 0)
            + (orderStatusCount.PUBLISHED ?? 0)
            + (orderStatusCount.ACCEPTED ?? 0)
            + (orderStatusCount.STARTED ?? 0);
    }, [orderStatusCount]);

    const activeJobsCount = React.useMemo(() => {
        return (jobStatusCount.CAPACITIES ?? 0)
            + (jobStatusCount.BOOKMARKED ?? 0)
            + (jobStatusCount.OFFERED ?? 0)
            + (jobStatusCount.ACCEPTED ?? 0)
            + (jobStatusCount.STARTED ?? 0);
    }, [jobStatusCount]);

    const goToMarketJobs = React.useCallback(() => {
        dispatch(resetMarketFilters({ isCapacity: false }));
        navigate('/market');
    }, [dispatch, navigate]);

    const goToMarketCapacities = React.useCallback(() => {
        dispatch(resetMarketFilters({ isJob: false }));
        navigate('/market');
    }, [dispatch, navigate]);

    return (
        <Container>
            <Header
                title={`Hej ${userProfile?.firstName || ''}!`}
                hideBackButton
                CustomComponent={(
                    <Badge
                        badgeContent={notificationsCount}
                        color="error"
                    >
                        <IconButton
                            size="large"
                            onClick={() => navigate('/notifications')}
                        >
                            <Notifications />
                        </IconButton>
                    </Badge>
                )}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Block title="Boka">
                        <Box
                            sx={{
                                height: 300,
                                maxWidth: 300,
                                margin: 'auto',
                                position: 'relative'
                            }}
                        >
                            {activeOrdersCount ? (
                                <>
                                    <DoughnutChart data={orderData} />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '130px',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)'
                                        }}
                                    >
                                        <AssignmentTurnedIn sx={{ fontSize: 50 }} color="disabled" />
                                    </div>
                                </>
                            ) : (
                                <Avatar sx={{ height: '250px', width: '250px', margin: 'auto' }} variant="circular">
                                    <AssignmentTurnedIn sx={{ fontSize: 120, p: 2 }} />
                                </Avatar>
                            )}
                        </Box>

                        <Box sx={{ p: 4, pt: 2 }}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item minWidth="240px">
                                    <Button
                                        variant="contained"
                                        startIcon={<Add />}
                                        onClick={() => navigate('/orders/create')}
                                    >
                                        Publicera uppdrag
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Typography>
                                        Dela uppdrag som ni vill få hjälp att köra - antigen publikt eller i privata grupper.
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item minWidth="240px">
                                    <Button
                                        variant="outlined"
                                        startIcon={<Search />}
                                        onClick={goToMarketCapacities}
                                    >
                                        Se ledig kapacitet
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Typography>
                                        Sök bland ledig kapacitet och se om det finns någon bil som har möjlighet att ta med ert gods.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Block>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Block title="Kör">
                        <Box
                            sx={{
                                height: 300,
                                maxWidth: 300,
                                margin: 'auto',
                                position: 'relative'
                            }}
                        >
                            {activeJobsCount ? (
                                <>
                                    <DoughnutChart data={jobData} />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '120px',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)'
                                        }}
                                    >
                                        <LocalShipping sx={{ fontSize: 50 }} color="disabled" />
                                    </div>
                                </>
                            ) : (
                                <Avatar sx={{ height: '250px', width: '250px', margin: 'auto' }} variant="circular">
                                    <LocalShipping sx={{ fontSize: 120, p: 2 }} />
                                </Avatar>
                            )}
                        </Box>

                        <Box sx={{ p: 4, pt: 2 }}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item minWidth="280px">
                                    <Button
                                        variant="contained"
                                        startIcon={<Add />}
                                        onClick={() => navigate('/capacities/create')}
                                    >
                                        Publicera ledig kapacitet
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Typography>
                                        Dela er lediga kapacitet anonymt och få förslag på uppdrag som passar längs vägen.
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item minWidth="280px">
                                    <Button
                                        variant="outlined"
                                        startIcon={<Search />}
                                        onClick={goToMarketJobs}
                                    >
                                        Se publicerade uppdrag
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Typography>
                                        Sök bland publicerade uppdrag och anmäl intresse att köra.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Block>
                </Grid>

                <Grid item xs={12}>
                    <ActiveOffers />
                </Grid>

                {userProfile?.isAdmin && (
                    <Grid item xs={12} xl={6}>
                        <Block
                            title="Mina grupper"
                            ActionComponent={(
                                <Button
                                    variant="outlined"
                                    startIcon={<Add />}
                                    onClick={() => navigate('/groups/create')}
                                >
                                    Ny grupp
                                </Button>
                            )}
                        >
                            <Table
                                name="dashboardGroups"
                                columns={[{
                                    name: 'Namn',
                                    key: 'name'
                                }, {
                                    name: 'Senast publicerat',
                                    key: 'lastPublishedDate',
                                    type: 'datetime' as 'datetime',
                                }, {
                                    name: 'Publicerat',
                                    key: 'publishedCount',
                                    type: 'number' as 'number'
                                }]}
                                data={myGroups}
                                onRowClick={({ id }: { id: number }) => navigate(`/groups/${id}`)}
                                emptyText="Du är inte medlem i någon grupp."
                            />
                        </Block>
                    </Grid>
                )}

                <Grid item xs={12} xl={userProfile?.isAdmin ? 6 : 12}>
                    <Block
                        title="Mina bevakningar"
                        ActionComponent={(
                            <Button
                                variant="outlined"
                                startIcon={<Add />}
                                onClick={() => navigate('/watchlists/create')}
                            >
                                Ny bevakning
                            </Button>
                        )}
                    >
                        <Table
                            name="dashboardWatchlists"
                            columns={[{
                                name: 'Namn',
                                key: 'name',
                            }, {
                                name: 'Senast publicerat',
                                key: 'lastPublishedDate',
                                type: 'datetime' as 'datetime',
                            }, {
                                name: 'Publicerat',
                                key: 'publishedCount',
                                type: 'number' as 'number',
                            }]}
                            data={watchlists}
                            onRowClick={({ id }: { id: number }) => navigate(`/watchlists/${id}`)}
                            emptyText="Du har inga bevakningar."
                        />
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
}
