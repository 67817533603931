import apiRequest from './api-request';
import type { JobStatusType } from '../types';
import { OrderTypeType } from '../types';

const resource = 'jobs';

type OfferForCreateType = {
    price: number;
    expiresAt?: Date,
    message?: string
};

export function getJobStatusCount() {
    return apiRequest({
        method: 'GET',
        resource: 'job-status-count'
    });
}

export function getJobs({ status }: { status: JobStatusType | 'PUBLISHED' }) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}?status=${status}`
    });
}

export function getJob({ id, type = 'LL' }: { id: number | string, type?: OrderTypeType }) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/${type}/${id}`
    });
}

export function createOffer({ id, body }: { id: number | string, body: OfferForCreateType }) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${id}/offer`,
        body
    });
}

export function createNewOfferMessage({ orderId, message }: { orderId: number | string, message: string }) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${orderId}/offer-message`,
        body: { message }
    });
}

export function addBookmark({ id, type }: { id: number | string, type: OrderTypeType }) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${type}/${id}/bookmark`
    });
}

export function removeBookmark({ id, type }: { id: number | string, type: OrderTypeType }) {
    return apiRequest({
        method: 'DELETE',
        resource: `${resource}/${type}/${id}/bookmark`
    });
}
