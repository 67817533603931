export const orderStatus = {
    DRAFTED: 'DRAFTED',
    PUBLISHED: 'PUBLISHED',
    ACCEPTED: 'ACCEPTED',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED'
};

export const offerStatus = {
    PENDING: 'PENDING',
    EXPIRED: 'EXPIRED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED'
};
