import React from 'react';
import { JobType, OfferType, OrderType } from '../../types';
import OfferForm from './OfferForm';
import OfferDetails from './OfferDetails';

type PropsType = {
    order: OrderType | JobType;
    offer: OfferType | null;
    refetch: () => void;
};

export default function Offer({
    order,
    offer,
    refetch
}: PropsType) {
    return offer?.price
        ? (
            <OfferDetails
                order={order}
                offer={offer}
                refetch={refetch}
            />
        ) : (
            <OfferForm
                order={order}
                refetch={refetch}
            />
        );
}
