import React from 'react';
import {
    // Divider,
    Grid,
    Typography
} from '@mui/material';

import { formatNumber } from '../../utils/parsing';
import { JobType, OfferType, OrderType } from '../../types';
import { OfferStatusChip } from './OfferChips';
import EditOffer from './EditOffer';
import AcceptOffer from './AcceptOffer';
import RejectOffer from './RejectOffer';
import { offerStatus } from '../../utils/status';
// import { COLOR_NEW_BLUE } from '../../theme';
// import PastOffers from './PastOffers';
import { InfoText } from '../CommonComponents';

type PropsType = {
    order: OrderType | JobType;
    offer: OfferType;
    refetch: () => void;
};

export default function OfferDetails({
    order,
    offer,
    refetch
}: PropsType) {
    return (
        <Grid item>
            <Grid container spacing={1} alignItems="flex-end" justifyContent="flex-end">
                <Grid item xs>
                    <Typography variant="h6" color="primary">
                        Prisförslag
                    </Typography>
                </Grid>
                <Grid item>
                    <OfferStatusChip
                        price={offer.price}
                        status={offer.status}
                        expiresAt={offer.expiresAt}
                        rejectedAt={offer.rejectedAt}
                    />
                </Grid>

                <Grid item xs={12} style={{ padding: 0 }} />

                <Grid item xs>
                    <Typography variant="body2" noWrap>
                        {
                            offer.isOwnOffer
                                ? 'Ni erbjuder'
                                : 'Ni erbjuds'
                        }
                    </Typography>

                    <Typography variant="h5" noWrap>
                        {formatNumber(offer.price, 'SEK')}
                    </Typography>
                </Grid>

                {offer.status !== offerStatus.ACCEPTED && order.isOpen && (
                    // Would be better to check if offer.status === 'PUBLISED', but it's not always present
                    <Grid item>
                        <EditOffer
                            order={order}
                            offer={offer}
                            refetch={refetch}
                        />
                    </Grid>
                )}

                {offer.status !== offerStatus.ACCEPTED && !order.isOpen && (
                    <Grid item>
                        <InfoText showBorder>
                            Uppdraget är inte längre aktivt, så ni kan inte skicka nya prisförslag.
                        </InfoText>
                    </Grid>
                )}

                {/* <Grid item xs={12}>
                    <Divider />
                    <PastOffers />
                    <Typography variant="caption" display="block" align="center">
                        {`Skickat ${
                            formatDateTime(offer?.priceAt)
                        } av ${offer?.priceByName}, ${offer?.priceByTenantName}`}
                    </Typography>
                </Grid> */}

                {
                    order.isOpen
                    && !offer.isOwnOffer
                    && offer.status === offerStatus.PENDING
                    && (
                        <>
                            <Grid item xs={6}>
                                <AcceptOffer
                                    offer={offer}
                                    refetch={refetch}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <RejectOffer
                                    offer={offer}
                                    refetch={refetch}
                                />
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </Grid>
    );
}
