import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Button } from '@mui/material';
import {
    Archive,
    // Cancel,
    ChevronLeft,
    ChevronRight,
    Delete,
    SaveAlt,
    Unarchive
} from '@mui/icons-material';

import { Header, NewTable } from '../CommonComponents';
import {
    Container, Paper,
} from '../StyledComponents';

import {
    fetchDraftedOrders,
    fetchPublishedOrders,
    fetchAcceptedOrders,
    fetchStartedOrders,
    fetchCompletedOrders,
    fetchArchivedOrders,
    fetchOrderStatusCount,
    setActiveOrderTab,
    selectDraftedOrders,
    selectPublishedOrders,
    selectAcceptedOrders,
    selectStartedOrders,
    selectCompletedOrders,
    selectArchivedOrders,
    selectOrderStatusCount,
    selectActiveOrderTab
} from '../../redux/actions/orders';
import type { OrderStatusType } from '../../types';

import { useDispatch, useSelector } from '../../redux/hooks';
import { getOrderDate, getOrderExpiryDate } from '../../utils/order';
import { formatStatus } from '../../utils/parsing';
import {
    setOrdersArchived,
    setOrdersCompleted,
    setOrdersDeleted,
    setOrdersDrafted,
    setOrdersPublished,
    setOrdersStarted
} from '../../api/orders';
import { formatMessage } from '../../utils/toast';

type BatchResultType = { ok: boolean, error?: { message: string }, value: any };

const commonColumns = [{
    name: 'Transportdatum',
    key: 'orderDate',
    getValue: getOrderDate,
    canSearch: true
}, {
    name: 'Upphämtningsort',
    key: 'pickupCity',
    canSearch: true
}, {
    name: 'Leveransort',
    key: 'deliveryCity',
    canSearch: true
}, {
    name: 'Körsträcka',
    key: 'drivingDistance',
    type: 'km' as 'km',
    getValue: (r: { drivingDistance: number }) => Math.round(r.drivingDistance / 1000)
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg'
}];

const draftedColumns = [...commonColumns, {
    name: 'Riktpris',
    key: 'listPrice',
    type: 'currency' as 'currency'
}, {
    name: 'Anmäl senast',
    key: 'expiresAt',
    type: 'datetime' as 'datetime'
}];

const publishedColumns = [...commonColumns, {
    name: 'Riktpris',
    key: 'listPrice',
    type: 'currency' as 'currency'
}, {
    name: 'Anmäl senast',
    key: 'expiresAt',
    type: 'datetime' as 'datetime'
}, {
    name: 'Intresseanm.',
    key: 'offerCount',
    type: 'number' as 'number'
}];

const acceptedColumns = [...commonColumns, {
    name: 'Pris',
    key: 'acceptedPrice',
    type: 'currency' as 'currency'
}, {
    name: 'Tilldelat',
    key: 'acceptedTenantName',
    canSearch: true
}];

const archivedColumns = [...commonColumns, {
    name: 'Ritkpris',
    key: 'listPrice',
    type: 'currency' as 'currency'
}, {
    name: 'Pris',
    key: 'acceptedPrice',
    type: 'currency' as 'currency'
}, {
    name: 'Tilldelat',
    key: 'acceptedTenantName',
    canSearch: true
}, {
    name: 'Status',
    key: 'status',
    getValue: (r: { status: OrderStatusType }) => formatStatus(r.status),
    canSearch: true
}];

const columns = {
    DRAFTED: draftedColumns,
    PUBLISHED: publishedColumns,
    ACCEPTED: acceptedColumns,
    STARTED: acceptedColumns,
    COMPLETED: acceptedColumns,
    ARCHIVED: archivedColumns
};

const tabs = [
    { status: 'DRAFTED', title: 'Opublicerade' },
    { status: 'PUBLISHED', title: 'Publicerade' },
    { status: 'ACCEPTED', title: 'Tilldelade' },
    { status: 'STARTED', title: 'Bekräftade' },
    { status: 'COMPLETED', title: 'Utförda' },
    { status: 'ARCHIVED', title: 'Arkiverade' },
] as { status: OrderStatusType, title: string }[];

export default function Orders() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orderStatusCount = useSelector(selectOrderStatusCount());
    const orders = {
        DRAFTED: useSelector(selectDraftedOrders()),
        PUBLISHED: useSelector(selectPublishedOrders()),
        ACCEPTED: useSelector(selectAcceptedOrders()),
        STARTED: useSelector(selectStartedOrders()),
        COMPLETED: useSelector(selectCompletedOrders()),
        ARCHIVED: useSelector(selectArchivedOrders())
    };

    const now = React.useMemo(() => new Date(), []);

    const activeOrderTab = useSelector(selectActiveOrderTab());
    const changeTab = React.useCallback(async (event, orderStatus) => {
        switch (orderStatus) {
            case 'DRAFTED':
                dispatch(fetchDraftedOrders());
                break;
            case 'PUBLISHED':
                dispatch(fetchPublishedOrders());
                break;
            case 'ACCEPTED':
                dispatch(fetchAcceptedOrders());
                break;
            case 'STARTED':
                dispatch(fetchStartedOrders());
                break;
            case 'COMPLETED':
                dispatch(fetchCompletedOrders());
                break;
            case 'ARCHIVED':
                dispatch(fetchArchivedOrders());
                break;
            default:
                break;
        }
        dispatch(fetchOrderStatusCount());
        dispatch(setActiveOrderTab(orderStatus));
    }, [dispatch]);

    React.useEffect(() => {
        changeTab(null, activeOrderTab);
        // We only want to reoload the current tab once when the component mounts
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeTab]);

    const handleBatchAction = React.useCallback(async ({
        rows,
        batchAction,
        toVerb,
        isVerb
    }: {
        rows: { id: number }[];
        batchAction: (r: { ids: number[] }) => Promise<BatchResultType[]>;
        toVerb: string;
        isVerb: string;
    }) => {
        try {
            const ids = rows.map((row) => row.id);
            const result = await batchAction({ ids });

            changeTab(null, activeOrderTab); // Refetch current tab

            const errorMessages = result
                .filter((r) => !r.ok)
                .map((r) => r?.error?.message);

            const successfulCount = ids.length - errorMessages.length;

            const resultText = `${successfulCount}/${ids.length} uppdrag ${isVerb}.`;

            return `
                ${resultText}

                ${errorMessages.map((m, i) => `${i + 1}: ${m}`).join('\n')}
            `;
        } catch (e) {
            return formatMessage(e) || `Kunde inte ${toVerb} uppdragen, försök igen.`;
        }
    }, [activeOrderTab, changeTab]);

    const archiveOrders = React.useCallback((
        rows: { id: number }[],
        archive: boolean = true
    ) => handleBatchAction({
        rows,
        batchAction: ({ ids }) => setOrdersArchived({ ids, archive }),
        toVerb: archive ? 'arkivera' : 'avarkivera',
        isVerb: archive ? 'arkiverade' : 'avarkiverade'
    }), [handleBatchAction]);

    const deleteOrders = React.useCallback((
        rows: { id: number }[]
    ) => handleBatchAction({
        rows,
        batchAction: setOrdersDeleted,
        toVerb: 'ta bort',
        isVerb: 'borttagna'
    }), [handleBatchAction]);

    const publishOrders = React.useCallback((
        rows: { id: number }[]
    ) => handleBatchAction({
        rows,
        batchAction: setOrdersPublished,
        toVerb: 'publicera',
        isVerb: 'publicerade'
    }), [handleBatchAction]);

    const draftOrders = React.useCallback((
        rows: { id: number }[]
    ) => handleBatchAction({
        rows,
        batchAction: setOrdersDrafted,
        toVerb: 'avpublicera',
        isVerb: 'avpublicerade'
    }), [handleBatchAction]);

    const startOrders = React.useCallback((
        rows: { id: number }[]
    ) => handleBatchAction({
        rows,
        batchAction: setOrdersStarted,
        toVerb: 'bekräfta',
        isVerb: 'bekräftade'
    }), [handleBatchAction]);

    const completeOrders = React.useCallback((
        rows: { id: number }[]
    ) => handleBatchAction({
        rows,
        batchAction: setOrdersCompleted,
        toVerb: 'klarmarkera',
        isVerb: 'klarmarkerade'
    }), [handleBatchAction]);

    const batchActions = React.useMemo(() => ({
        DRAFTED: [{
            name: 'Ta bort',
            icon: <Delete />,
            color: 'error' as 'error',
            action: deleteOrders,
            confirmText: `
                Vill du verkligen ta bort de valda uppdragen?
                Du kommer inte kunna återställa dem igen när du tagit bort dem.`
        }, {
            name: 'Arkivera',
            icon: <Archive />,
            action: archiveOrders,
            confirmText: 'Vill du verkligen arkivera de valda uppdragen?'
        }, {
            name: 'Publicera',
            endIcon: <ChevronRight />,
            action: publishOrders,
            confirmText: `
                Vill du verkligen publicera de valda uppdragen?
                De kommer att visas på marknadsplatsen för de grupper du valt.`
        }],
        PUBLISHED: [{
            name: 'Ta bort',
            icon: <Delete />,
            color: 'error' as 'error',
            action: deleteOrders,
            confirmText: `
                Vill du verkligen ta bort de valda uppdragen?
                Du kommer inte kunna återställa dem igen när du tagit bort dem.`
        }, {
            name: 'Arkivera',
            icon: <Archive />,
            action: archiveOrders,
            confirmText: `
                Vill du verkligen arkivera de valda uppdragen?
                Uppdragen kommer inte längre att synas på marknadsplatsen.`
        }, {
            name: 'Avpublicera',
            icon: <ChevronLeft />,
            action: draftOrders,
            confirmText: `
                Vill du verkligen avpublicera de valda uppdragen?
                Uppdragen kommer inte längre att synas på marknadsplatsen.`
        }],
        ACCEPTED: [
            // {
            //     name: 'Avbryt',
            //     icon: <Cancel />,
            //     color: 'error' as 'error',
            //     action: async () => 'Ej implementerat',
            //     confirmText: 'Vill du verkligen avbryta de valda uppdragen?'
            // },
            {
                name: 'Bekräfta',
                endIcon: <ChevronRight />,
                action: startOrders,
                confirmText: 'Vill du verkligen bekräfta de valda uppdragen?'
            }],
        STARTED: [
            // {
            //     name: 'Avbryt',
            //     icon: <Cancel />,
            //     color: 'error' as 'error',
            //     action: async () => 'Ej implementerat',
            //     confirmText: 'Vill du verkligen avbryta de valda uppdragen?'
            // },
            {
                name: 'Klarmarkera',
                endIcon: <ChevronRight />,
                action: completeOrders,
                confirmText: 'Vill du verkligen klarmarkera de valda uppdragen?'
            }],
        COMPLETED: [{
            name: 'Ta bort',
            icon: <Delete />,
            color: 'error' as 'error',
            action: deleteOrders,
            confirmText: `
                Vill du verkligen ta bort de valda uppdragen?
                Du kommer inte kunna återställa dem igen när du tagit bort dem.
            `
        }, {
            name: 'Arkivera',
            icon: <Archive />,
            action: archiveOrders,
            confirmText: 'Vill du verkligen arkivera de valda uppdragen?'
        }],
        ARCHIVED: [{
            name: 'Ta bort',
            icon: <Delete />,
            color: 'error' as 'error',
            action: deleteOrders,
            confirmText: `
                Vill du verkligen ta bort de valda uppdragen?
                Du kommer inte kunna återställa dem igen när du tagit bort dem.
            `
        }, {
            name: 'Avarkivera',
            icon: <Unarchive />,
            action: (rows: { id: number }[]) => archiveOrders(rows, false),
            confirmText: `
                Vill du verkligen avarkivera de valda uppdragen?
                Uppdragen kommer placeras under respektive statusflik.
            `
        }]
    }), [archiveOrders, completeOrders, deleteOrders, draftOrders, publishOrders, startOrders]);

    return (
        <Container>
            <Header
                title="Boka uppdrag"
                addUrl="/orders/create"
                addUrlTitle="Nytt uppdrag"
                CustomComponent={(
                    <Button
                        startIcon={<SaveAlt />}
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate('/orders/import')}
                    >
                        Importera
                    </Button>
                )}
            />

            <Tabs
                value={activeOrderTab}
                onChange={changeTab}
                aria-label="Orders"
                variant="fullWidth"
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.status}
                        value={tab.status}
                        label={`${tab.title} (${orderStatusCount[tab.status] ?? '-'})`}
                    />
                ))}
            </Tabs>

            <Paper padding={0}>
                <NewTable
                    name={`orders${activeOrderTab}`}
                    columns={columns[activeOrderTab]}
                    data={orders[activeOrderTab]}
                    onRowClick={({ id }: any) => navigate(`/orders/${id}`)}
                    getRowStyle={(order) => (
                        (activeOrderTab === 'DRAFTED' || activeOrderTab === 'PUBLISHED')
                            && getOrderExpiryDate(order) < now
                            ? ({ backgroundColor: '#FCC' })
                            : undefined
                    )}
                    emptyText={
                        `Ni har inga ${tabs.find((t) => t.status === activeOrderTab)?.title.toLocaleLowerCase()
                        } uppdrag.`
                    }
                    batchActions={batchActions[activeOrderTab]}
                    showPagination
                />
            </Paper>
        </Container>
    );
}
