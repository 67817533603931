import React from 'react';

import {
    Chip,
    Grid,
    Typography
} from '@mui/material';
import { Info } from '@mui/icons-material';

import { JobType, OrderType } from '../../types';
import { getFormattedOrderExpiryDate } from '../../utils/order';
import OfferFormFields from './OfferFormFields';
import SimpleForm from '../CommonComponents/Form/SimpleForm';
import { useChangeable } from '../CommonComponents/Form';
import { InfoText } from '../CommonComponents';
import toastMessage from '../../utils/toast';
import { createOffer } from '../../api/offers';
import NotifySentOfferPopup from './NotifySentOfferPopup';

export const defaultOffer = {
    price: '',
    expiresAt: ''
};

type PropsType = {
    order: OrderType | JobType;
    refetch: () => void;
}

export default function OfferForm({ order, refetch }: PropsType) {
    const [newOffer, onChange] = useChangeable(defaultOffer);
    const [showNotifySentPopup, setShowNotifySentPopup] = React.useState(false);

    const sendOffer = React.useCallback(async () => {
        try {
            await createOffer({ orderId: order.id, body: newOffer });
            setShowNotifySentPopup(true);
        } catch (e) {
            toastMessage(e, 'Kunde inte skicka prisförslag, försök igen.');
            refetch();
        }
    }, [newOffer, order.id, refetch]);

    const closeNotifySentPopup = React.useCallback(() => {
        setShowNotifySentPopup(false);
        refetch();
    }, [refetch]);

    return (
        <Grid container spacing={1}>
            <NotifySentOfferPopup
                open={showNotifySentPopup}
                handleClose={closeNotifySentPopup}
                expiresAt={newOffer.expiresAt}
                price={newOffer.price}
            />
            <Grid item xs>
                <Typography variant="h6" color="primary">
                    Prisförslag
                </Typography>
            </Grid>
            <Grid item>
                <Chip
                    icon={<Info />}
                    label={`Skicka senast ${getFormattedOrderExpiryDate(order)}`}
                />
            </Grid>

            <Grid item xs={12}>
                <SimpleForm
                    onSubmit={sendOffer}
                    submitText="Föreslå pris"
                    submitDisabled={!newOffer.price || !order.isOpen}
                    useGrid
                >
                    <OfferFormFields
                        price={newOffer.price}
                        expiresAt={newOffer.expiresAt}
                        onChange={onChange}
                        listPrice={order.listPrice}
                        disabled={!order.isOpen}
                    />
                </SimpleForm>
            </Grid>
            {!order.isOpen && (
                <Grid item xs={12}>
                    <InfoText showBorder>
                        Uppdraget tar inte längre emot prisförslag.
                    </InfoText>
                </Grid>
            )}
        </Grid>
    );
}
