import React from 'react';
import { Grid } from '@mui/material';

import {
    DateTimeField,
    NumberField
} from '../CommonComponents/Form';
import { formatNumber } from '../../utils/parsing';

type PropsType = {
    price: string;
    expiresAt: string | null;
    listPrice: number | null;
    onChange: (a: any) => void;
    disabled: boolean;
}

export default function OfferFormFields({
    price,
    expiresAt,
    listPrice,
    onChange,
    disabled = false
}: PropsType) {
    return (
        <>
            <Grid item xs={6}>
                <NumberField
                    name="price"
                    label="Ert prisförslag"
                    helperText={
                        listPrice
                            ? `Riktpris: ${formatNumber(listPrice, 'SEK')}`
                            : 'Inget riktpris angivet'
                    }
                    value={price}
                    onChange={onChange}
                    minValue={0}
                    maxValue={100000}
                    disabled={disabled}
                    // required
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    name="expiresAt"
                    label="Besvaras senast"
                    value={expiresAt || ''}
                    onChange={onChange}
                    minDateTime={new Date()}
                    disabled={disabled}
                />
            </Grid>
        </>
    );
}
